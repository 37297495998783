import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import moment from "moment";
import numeral from "numeral";
import DateTimePicker from "../DatePicker.js";
import ImageUri from "../ImageUri";
import UploadFirebase from "../UploadFirebase";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import OpenApiSuffix from './SMCOpenApiSuffix.js'

UIkit.use(Icons);

export default class SMCReport extends Component {
  state = {
    dateStart: moment().subtract(1, "day").startOf("d"),
    dateStop: moment().endOf("d"),
    getItemExpensePopupShow: false,
    group: "all",
    list: [],
    imagePreview: null,
    imagePatientPreview: null,
    imagePatientPreviewNid: '',
    loadingNotiApi: false,
    loadingShippingConfirmPrice: false,
    statistic: {
      ฟังผลตรวจสุขภาพ: {},
      รับยาต่อเนื่อง: {},
      รับยาเดิมไม่พบแพทย์: {},
      ใบนัด: {},
      ใบนัดจิตแพทย์: {},
    },
    consultList: [],
    personSelect: "all",
    summaryRawData: {},
    pageCount: 0,
    pageNow: 1,
    perPgae: 200
  };
 
  getItemExpenseData = {}

  componentDidMount() {
    this.listGet();
    this.consultListGet();
  }

  consultListGet = async () => {
    var _response = await Api.consult("ConsultAllInCompany", {});

    var _consult = [
      {
        label: "รวมทุกคน",
        value: "all",
      },
    ];

    _response.list.forEach(function (consult) {
      _consult.push({
        label: `${consult.nameprefix} ${consult.name} ${consult.lastname}`,
        value: consult.id,
      });
    });

    this.setState({ consultList: _consult });
  };

  PrintShipping = async (data) => {
    if (
      data.purchase_dealer === "shippop" &&
      data.purchase_id &&
      data.trackingcode
    ) {
      var _response = await Api.consult("PrescriptionLabelGet", {
        purchaseId: data.purchase_id,
        trackingCode: data.trackingcode,
        dealer: data.purchase_dealer,
      });

      if (_response.data && _response.data.status === true) {
        var win = window.open(
          "",
          "Title",
          "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
        );

        win.document.body.innerHTML = _response.data.html.replace(
          /src="\/\//g,
          'src="https://'
        );
      } else {
        UIkit.notification("ไม่สามารถดึงข้อมูลรายการนี้ได้");
      }
    } else {
      UIkit.notification("ไม่พบข้อมูลสำหรับพิมพ์ใบปะหน้าของรายการนี้");
    }
  };

  staticTableType = [
    "ฟังผลตรวจสุขภาพ",
    "รับยาต่อเนื่อง",
    "รับยาเดิมไม่พบแพทย์",
    "ใบนัด",
    "ใบนัดจิตแพทย์",
  ];

  staticTableRender = () => {
    var _summary = {};

    return (
      <div className="uk-overflow-auto">
        <h4 className="uk-margin-top uk-text-center uk-text-bold">
          รายงานการบริการ ณ วันที่{" "}
          {moment(this.state.dateStart).format("DD/MM/YYYY")} ถึงวันที่{" "}
          {moment(this.state.dateStop).format("DD/MM/YYYY")}
        </h4>

        <table className="uk-table uk-table-divider uk-table-striped uk-margin-remove-top">
          <thead>
            <tr>
              <th rowSpan={2}>บริการ</th>
              <th colSpan={4}>จำนวน visit</th>
              <th colSpan={3}>จำนวนจัดส่ง</th>
              <th colSpan={3}>ยอดรายได้</th>
            </tr>
            <tr>
              <th>จำนวนทั้งหมด</th>
              <th>รอดำเนินการ</th>
              <th>สำเร็จ</th>
              <th>ยกเลิก</th>
              <th>Drive thru</th>
              <th>ส่งพัสดุ</th>
              <th>ไม่เลือกจัดส่ง</th>
              <th>เบิกได้</th>
              <th>เบิกไม่ได้</th>
              <th>รวมยอด</th>
            </tr>
          </thead>
          <tbody>
            {this.staticTableType.map((type, index) => {
              for (var key in this.state.statistic[type]) {
                if (_summary[key] == null) {
                  _summary[key] = 0;
                }

                _summary[key] += this.state.statistic[type][key];
              }

              return (
                <tr key={`statistic${index}`}>
                  <td>{type}</td>
                  <td>
                    {numeral(this.state.statistic[type].visit).format("0,0")}
                  </td>
                  <td>
                    {numeral(this.state.statistic[type].waiting).format("0,0")}
                  </td>
                  <td>
                    {numeral(this.state.statistic[type].finish).format("0,0")}
                  </td>
                  <td>
                    {numeral(this.state.statistic[type].cancel).format("0,0")}
                  </td>
                  <td>
                    {numeral(this.state.statistic[type].drivethru).format(
                      "0,0"
                    )}
                  </td>
                  <td>
                    {numeral(this.state.statistic[type].ems).format("0,0")}
                  </td>
                  <td>
                    {numeral(this.state.statistic[type].no_shipping).format(
                      "0,0"
                    )}
                  </td>
                  <td>
                    {numeral(this.state.statistic[type].in_right_amount).format(
                      "0,0.00"
                    )}
                  </td>
                  <td>
                    {numeral(this.state.statistic[type].pay_amount).format(
                      "0,0.00"
                    )}
                  </td>
                  <td>
                    {numeral(this.state.statistic[type].sum_amount).format(
                      "0,0.00"
                    )}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>รวม</td>
              <td>{numeral(_summary.visit).format("0,0")}</td>
              <td>{numeral(_summary.waiting).format("0,0")}</td>
              <td>{numeral(_summary.finish).format("0,0")}</td>
              <td>{numeral(_summary.cancel).format("0,0")}</td>
              <td>{numeral(_summary.drivethru).format("0,0")}</td>
              <td>{numeral(_summary.ems).format("0,0")}</td>
              <td>{numeral(_summary.no_shipping).format("0,0")}</td>
              <td>{numeral(_summary.in_right_amount).format("0,0.00")}</td>
              <td>{numeral(_summary.pay_amount).format("0,0.00")}</td>
              <td>{numeral(_summary.sum_amount).format("0,0.00")}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  listGet = async () => {
    var _response = await Api.consult("SMCList", {
      group: this.state.group,
      dateStart: moment(this.state.dateStart).format("YYYY-MM-DD"),
      dateStop: moment(this.state.dateStop).format("YYYY-MM-DD"),
    });

    this.setState(
      {
        summaryRawData: _response,
      },
      () => {
        this.listSummaryRender();
      }
    );
  };

  listSummaryRender = async () => {
    var _response = this.state.summaryRawData;

    var _statistic = {
      ฟังผลตรวจสุขภาพ: {
        visit: 0,
        finish: 0,
        cancel: 0,
        waiting: 0,
        drivethru: 0,
        ems: 0,
        no_shipping: 0,
        pay_amount: 0,
        in_right_amount: 0,
        sum_amount: 0,
      },
      รับยาต่อเนื่อง: {
        visit: 0,
        finish: 0,
        cancel: 0,
        waiting: 0,
        drivethru: 0,
        ems: 0,
        no_shipping: 0,
        pay_amount: 0,
        in_right_amount: 0,
        sum_amount: 0,
      },
      รับยาเดิมไม่พบแพทย์: {
        visit: 0,
        finish: 0,
        cancel: 0,
        waiting: 0,
        drivethru: 0,
        ems: 0,
        no_shipping: 0,
        pay_amount: 0,
        in_right_amount: 0,
        sum_amount: 0,
      },
      ใบนัด: {
        visit: 0,
        finish: 0,
        cancel: 0,
        waiting: 0,
        drivethru: 0,
        ems: 0,
        no_shipping: 0,
        pay_amount: 0,
        in_right_amount: 0,
        sum_amount: 0,
      },
      ใบนัดจิตแพทย์: {
        visit: 0,
        finish: 0,
        cancel: 0,
        waiting: 0,
        drivethru: 0,
        ems: 0,
        no_shipping: 0,
        pay_amount: 0,
        in_right_amount: 0,
        sum_amount: 0,
      },
    };

    if (Array.isArray(_response.list)) {
      _response.list.forEach((data) => {
        if (
          this.state.group === "all" &&
          (this.state.personSelect === "all" ||
            this.state.personSelect === data.consultid)
        ) {
          if (!_statistic[data.type]) {
            _statistic[data.type] = {
              visit: 0,
              finish: 0,
              cancel: 0,
              waiting: 0,
              drivethru: 0,
              ems: 0,
              no_shipping: 0,
              pay_amount: 0,
              in_right_amount: 0,
              sum_amount: 0,
            };
          }

          _statistic[data.type].visit++;

          if (data.finish == "1") {
            if (data.note.includes("ยกเลิก")) {
              _statistic[data.type].cancel++;
            } else {
              _statistic[data.type].finish++;
            }
          } else {
            _statistic[data.type].waiting++;
          }

          if (data.shipping === "DRIVETHRU") {
            _statistic[data.type].drivethru++;
          } else if (data.shipping === "") {
            _statistic[data.type].no_shipping++;
          } else {
            _statistic[data.type].ems++;
          }

          _statistic[data.type].pay_amount += parseFloat(data.payamount);
          _statistic[data.type].in_right_amount += parseFloat(
            data.in_right_amount
          );
          _statistic[data.type].sum_amount +=
            parseFloat(data.in_right_amount) + parseFloat(data.payamount);
        }
      });
    }

    if (!Array.isArray(_response.list)) {
      _response.list = [];
    }

    this.setState({
      list: _response.list,
      statistic: _statistic,
      loadingShippingConfirmPrice: false,
      pageCount: Math.ceil(_response.list.length / this.state.perPgae)
    });
  };

  paymentSlipUpdate = async (file, id, smc_id) => {
    var _response = await Api.consult("SMCPaymentSlipUpdate", {
      file,
      id,
      smc_id,
    });

    this.listGet();
  };

  getItemExpenseDateGet = async (campaignid, id) => {
    this.setState({ loadingNotiApi: true });
    var _url = `https://server.dietz.asia/openApi/${OpenApiSuffix[campaignid].prefix
  }/${OpenApiSuffix[campaignid].version
  }/get_item_expense_data_get.php?smc_id=${id}`; 
    $.get(
    
      _url
      ,
       (data) => {
        console.log(data)
        this.getItemExpenseData = data || {};

        this.getItemExpenseData.id = id;
        this.getItemExpenseData.campaignid = campaignid;

        this.setState({loadingNotiApi: false});
      }
    );
    
  }

  paymentNotiUpdate = async (id, campaignid) => {
    var _api_suffix = OpenApiSuffix[campaignid];

    this.setState({ loadingNotiApi: true });

    var _response = await Api.consult("SMCPaymentNoti2", {
      detail: this.getItemExpenseData,
      id,
      campaignid,
      ..._api_suffix
    });

    this.listGet();

    this.setState({ loadingNotiApi: false, getItemExpensePopupShow : false  });
  };

  smcStatusUpdate = async (data) => {
    var _response = await Api.consult("SMCStatusUpdate", {
      id: data.id,
      key: data.key,
      value: data.value,
    });
  };

  smcCancelUpdate = async (id, name) => {
    if (window.confirm("ยืนยันการยกเลิก " + name + " ?")) {
      var _response = await Api.consult("SMCCancelUpdate", {
        id: id,
      });

      this.listGet();
    }
  };

  smcFinishUpdate = async (id, name) => {
    if (window.confirm("ยืนยันการทำรายการสำเร็จ " + name + " ?")) {
      var _response = await Api.consult("SMCFinishUpdate", {
        id: id,
      });

      this.listGet();
    }
  };

  shippingLogRender = (log) => {
    try {
      var _data = JSON.parse(log);

      var _row = [];

      for (var key in _data) {
        _row.push(<div><b>{key} :</b> {_data[key]}</div>)
      }


      return <span>
        <span uk-icon="icon: question"></span>
        <div class="uk-width-large" uk-dropdown="mode:hover;">{_row}</div>
      </span>
    }
    catch (e) {
      console.log(e);
      return "";
    }
  }

  shippingGrabCancel = async (id) => {

    var _response = await Api.consult("SMCShippingGrabCancel", {
      id: id,
    });

    this.listGet();
  }

  paginateHandlePageClick = (page) => {
    this.setState({ pageNow: page.selected + 1 })
  }

  paginateRender = () => {
    return <div className="uk-text-right">
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={this.state.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.paginateHandlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  }

  rowRender = () => {
    if (this.state.list.length === 0) {
      return (
        <tr>
          <td colSpan={"100%"}>
            <div className="uk-padding-small uk-text-center">ไม่พบรายการ</div>
          </td>
        </tr>
      );
    } else {

      var _list = this.state.list.slice((this.state.pageNow - 1) * this.state.perPgae, this.state.pageNow * this.state.perPgae)

      return _list.map((data, index) => {
        if (data.detail == null) {
          data.detail = {};
        }

        if (data.token == null) {
          data.token = {};
        }
 
        var _checklist = [
          {
            check: true,
            date_label: (data.date_add.includes("0000-00-00") ?  '' : `(${moment(data.date_add, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YYYY เวลา HH:mm น."
            )})`),
            label: 'ยืนยันใบนัดและชำระค่ามัดจำ'
          },
          {
            check: data.hospital_open_visit_noti == 1,
            date_label: (data.hospital_open_visit_noti_date.includes("0000-00-00") ?  '' : `(${moment(data.hospital_open_visit_noti_date, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YYYY เวลา HH:mm น."
            )})`),
            label: 'เปิด Visit'
          },
          {
            check: data.videocall_nurse_message_noti == 1,
            date_label: (data.videocall_nurse_message_noti_date.includes("0000-00-00") ?  '' : `(${moment(data.videocall_nurse_message_noti_date, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YYYY เวลา HH:mm น."
            )})`),
            label: 'พบพยาบาล'
          },
          {
            check: data.videocall_message_noti == 1,
            date_label: (data.videocall_message_noti_date.includes("0000-00-00") ?  '' : `(${moment(data.videocall_message_noti_date, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YYYY เวลา HH:mm น."
            )})`),
            label: 'พบแพทย์'
          },
          {
            check: data.hospital_item_expense_noti == 1,
            date_label: (data.hospital_item_expense_noti_date.includes("0000-00-00") ?  '' : `(${moment(data.hospital_item_expense_noti_date, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YYYY เวลา HH:mm น."
            )})`),
            label: 'ออกใบแจ้งค่าใช้จ่าย'
          },
          {
            check: data.pay == 1,
            date_label: (data.pay_date.includes("0000-00-00") ?  '' : `(${moment(data.pay_date, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YYYY เวลา HH:mm น."
            )})`),
            label: 'ชำระค่าใช้จ่าย'
          },
          {
            check: data.finish == 1 && !data.note.includes("ยกเลิก"),
            date_label: (data.finish_date.includes("0000-00-00") ?  '' : `(${moment(data.finish_date, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YYYY เวลา HH:mm น."
            )})`),
            label: 'ชำระค่าจัดส่ง'
          },
        ]; 

        if(data.note.includes("ยกเลิก")) {
          _checklist.push({
            check: true,
            date_label: '',
            label: 'ยกเลิก',
            check_class: 'uk-text-danger'
          })
        }


        return (
          <tr key={`row${index}`}>
            <td data-id={data.id}>
              {((this.state.pageNow - 1) * this.state.perPgae) + index + 1}
              {data.finish != "1" && (
                <div>
                  <a
                    onClick={() => {
                      this.smcCancelUpdate(data.id, data.detail.patient);
                    }}
                  >
                    ยกเลิกรายการ
                  </a>
                </div>
              )}
              <div>(UID: {data.id})</div>
            </td>
            <td className="uk-text-left">
              <div>
                <b>วันที่ทำรายการ :</b>{" "}
                {data.date_add.includes("0000-00-00")
                  ? "-"
                  : moment(data.date_add, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY เวลา HH:mm น."
                  )}
              </div>
              <div className="uk-margin-small-top">
                <b>วันที่เปิด Visit :</b>{" "}
                {moment(data.hospital_open_visit_date, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )}
              </div>
              {data.detail && data.detail.date ? (
                <div className="uk-margin-small-top">
                  <b>วันที่นัด :</b> {data.detail.date} เวลา {data.detail.time}
                </div>
              ) : null}
              
              <div style={{width: '190px'}} className="uk-margin-top">
                {
                  _checklist.map((c, index)=>{
                    return <div key={`row-checklist-${index}-${data.id}`}>
                      <span className={"icon-check " + (c.check_class || (c.check === true ? 'uk-text-success' : ''))}></span> {c.label} {c.date_label ? <span className="uk-text-meta">{c.date_label}</span> : ''}
                    </div>
                  })
                }
              </div>
            </td>
            <td>
              <div>
                {data.typecode.includes("D10") ? "คลินิกสุขภาพจิต" : data.type}
              </div> 
              {
                data.hospital_item_expense_noti == "0" &&
                data.finish !== "1" &&
                this.state.loadingNotiApi === false && (
                  <div>
                    
                    <button
                      className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
                      onClick={() => {
                        if(OpenApiSuffix[data.campaignid].use_preview_box === true) {
                          this.getItemExpenseDateGet(data.campaignid, data.id)
                          this.setState({getItemExpensePopupShow: true});
                        } else {
                          this.paymentNotiUpdate(data.id, data.campaignid);
                        }
                      }}
                    >
                      ส่งแจ้งเตือนชำระเงิน
                    </button>
                  </div>
                )}
            </td>
            <td>
              {data.detail && data.detail.doctor != "null null null"
                ? data.detail.doctor
                : "-"}
            </td>
            <td>
              {data.detail.patient}
              {
                data.token.nid != null ? 
                <>
                  <div>
                    <b>เลขบัตรประชาชน :</b> {data.token.nid}
                  </div>
                
                  <a onClick={()=>{
                this.setState({imagePatientPreview: ImageUri(data.token.photo_url), imagePatientPreviewNid: data.token.nid})
              }}>
                <img src={ImageUri(data.token.photo_url)}  uk-img="" />
              </a>
                </>
                :
                <></>
              }
            {this.state.imagePatientPreview && (
              <div className="d-popup-background">
                <div className="d-popup-body uk-width-large">
                  <div><b>เลขบัตรประชาชน :</b> {this.state.imagePatientPreviewNid}</div>
                  <img src={this.state.imagePatientPreview} uk-img="" />
                  <div className="uk-margin-top uk-text-right">
                    <button
                      className="uk-button uk-button-secondary"
                      onClick={() => this.setState({ imagePatientPreview: null })}
                    >
                      ปิดภาพ
                    </button>
                  </div>
                </div>
              </div>
            )}
            </td>
            <td>{data.token.hn}</td>
            <td className="uk-text-left">
              {data.shipping === "DRIVETHRU" ? "Drive thru" : data.shipping}
              {data.shipping === "DRIVETHRU" ? (
                <div>
                  <div>
                    วันที่ :{" "}
                    {moment(data.address.day, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                  <div>โทร : {data.address.phone}</div>
                </div>
              ) : (
                ""
              )}
              {data.trackingcode ? (
                <div>
                  <div className="uk-margin-small-top">
                    {" "}
                    <a
                      rel="noopenner noreferer"
                      target="_blank"
                      href={
                        "https://www.shippop.com/tracking?typeid=domestic&tracking_code=" +
                        data.trackingcode
                      }
                    >
                      <span uk-icon="location"></span> ติดตามพัสดุเลขที่{" "}
                      {data.trackingcode}
                    </a>
                  </div>
                  <div className="uk-margin-small-top">
                    <a onClick={() => this.PrintShipping(data)}>
                      <span uk-icon="print"></span> พิมพ์ใบปะหน้า
                    </a>
                  </div>
                </div>
              ) : data.pay_noti == "1" &&
                !data.shipping &&
                OpenApiSuffix[data.campaignid] ? (
                this.state.loadingShippingConfirmPrice === true ? (
                  <div>
                    <span uk-spinner=""></span>
                  </div>
                ) : (
                  <a
                    className="uk-margin-samll-top uk-text-left"
                    onClick={() => {
                      this.setState({ loadingShippingConfirmPrice: true });

                      var _ref = data.id.toString().padStart(12, "0");

                      var _this = this;

                      $.get(
                        `https://server.dietz.asia/openApi/${OpenApiSuffix[data.campaignid].prefix
                        }/${OpenApiSuffix[data.campaignid].version
                        }/shipping_payment_confirm.php?refno=${_ref}&paysn=1`,
                        function (data) {
                          _this.listGet();
                        }
                      );
                    }}
                  >
                    <span uk-icon="history"></span>{" "}
                    ตรวจสอบการชำระเงินค่าจัดส่งอีกครั้ง
                  </a>
                )
              ) : (
                ""
              )}
              {data.shipping_status ? (
                <div className="uk-margin-small-top">
                  <b>สถานะจัดส่ง :</b> {data.shipping_status} {data.shipping_status_log ? this.shippingLogRender(data.shipping_status_log) : ""}
                </div>
              ) : (
                ""
              )}

              {data.shipping_price ? <div className="uk-margin-small-top"><b>ค่าจัดส่ง : </b> ฿ ${numeral(data.shipping_price).format('0,0.00')}</div> : ''}
              {data.shipping_status}
              {
                data.shipping === 'grab' &&
                  (
                    data.shipping_status.includes('Allocating') ||
                    data.shipping_status === 'PENDING_PICKUP' ||
                    data.shipping_status === 'PICKING_UP'
                  ) ?
                  <button className="uk-button uk-button-danger uk-margin-small-top" onClick={() => {
                    this.shippingGrabCancel(data.id);
                  }}>ยกเลิกจัดส่ง</button>
                  :
                  ""
              }




            </td>
            <td>
              <label>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  checked={data.send_open_visit == "1"}
                  onChange={() => {
                    data.send_open_visit =
                      data.send_open_visit == "1" ? "0" : "1";

                    this.smcStatusUpdate({
                      id: data.id,
                      key: "send_open_visit",
                      value: data.send_open_visit,
                    });

                    this.forceUpdate();
                  }}
                />
              </label>
            </td>
            <td>{data.dx || ""}</td>
            <td>
              {!data.purchase_dealer &&
                data.hospital_item_expense_noti == "1" &&
                data.finish != "1" &&
                (!data.smcpaymentstatus ||
                  !data.smcpaymentstatus.includes("รับยอดโอน")) ? (
                <div>
                  <div>{data.smcpaymentstatus}</div>

                  <label className="uk-width-1-1 uk-margin-small-top">
                    <a>กดเพื่ออัพเดทสลิป</a>
                    <UploadFirebase
                      maxWidth={1000}
                      maxHeight={1000}
                      accept={"image/*"}
                      cb={(file) => {
                        this.paymentSlipUpdate(
                          file,
                          data.smcpaymentid,
                          data.id
                        );
                      }}
                    />
                  </label>
                </div>
              ) : (
                data.smcpaymentstatus
              )}
            </td>
            <td></td>
            <td>{data.payamount}</td>
            <td>
              <label>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  checked={data.pay_full == "1"}
                  onChange={() => {
                    data.pay_full = data.pay_full == "1" ? "0" : "1";

                    this.smcStatusUpdate({
                      id: data.id,
                      key: "pay_full",
                      value: data.pay_full,
                    });

                    this.forceUpdate();
                  }}
                />
              </label>
            </td>
            <td>
              <label>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  checked={data.medicine == "1"}
                  onChange={() => {
                    data.medicine = data.medicine == "1" ? "0" : "1";

                    this.smcStatusUpdate({
                      id: data.id,
                      key: "medicine",
                      value: data.medicine,
                    });

                    this.forceUpdate();
                  }}
                />
              </label>
            </td>
            <td>
              <label>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  checked={data.receipt == "1"}
                  onChange={() => {
                    data.receipt = data.receipt == "1" ? "0" : "1";

                    this.smcStatusUpdate({
                      id: data.id,
                      key: "receipt",
                      value: data.receipt,
                    });

                    this.forceUpdate();
                  }}
                />
              </label>
            </td>
            <td>
              <label>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  checked={data.appointment == "1"}
                  onChange={() => {
                    data.appointment = data.appointment == "1" ? "0" : "1";

                    this.smcStatusUpdate({
                      id: data.id,
                      key: "appointment",
                      value: data.appointment,
                    });

                    this.forceUpdate();
                  }}
                />
              </label>
            </td>
            <td>
              <textarea
                className="uk-textarea uk-margin-bottom"
                value={data.note}
                onChange={(e) => {
                  data.note = e.target.value;
                  this.forceUpdate();
                }}
                onBlur={(e) => {
                  this.smcStatusUpdate({
                    id: data.id,
                    key: "note",
                    value: data.note,
                  });
                }}
              ></textarea>
              {data.finish != "1" && (
                <div>
                  <a
                    onClick={() => {
                      this.smcFinishUpdate(data.id, data.detail.patient);
                    }}
                  >
                    สำเร็จรายการ
                  </a>
                </div>
              )}
            </td>
          </tr>
        );
      });
    }
  };

  render() {
    return (
      <div>
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">รายงานการบริการ</div>
          <div uk-grid="" className="uk-flex-middle">
            <div className="uk-width-1-5@m uk-width-1-1">
              <DateTimePicker
                id="dateStart"
                date={this.state.dateStart}
                callback={(value) => this.setState({ dateStart: value })}
                format={"DD/MM/YYYY"}
              />
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
              <DateTimePicker
                id="dateStop"
                date={this.state.dateStop}
                callback={(value) => this.setState({ dateStop: value })}
                format={"DD/MM/YYYY"}
              />
            </div>
            <div className="uk-width-expand@m uk-width-1-1">
              <label className="uk-margin-small-right">
                <input
                  type="radio"
                  className="uk-radio"
                  name="smc_report_group_select"
                  checked={this.state.group === "self"}
                  onChange={() => this.setState({ group: "self" })}
                />{" "}
                เฉพาะตนเอง
              </label>

              <label className="uk-margin-small-right">
                <input
                  type="radio"
                  className="uk-radio"
                  name="smc_report_group_select"
                  checked={this.state.group === "all"}
                  onChange={() => this.setState({ group: "all" })}
                />{" "}
                รวมทุกคน
              </label>
            </div>
            <div className="uk-width-1-5@m uk-width-1-1">
              {this.state.group === "all" && (
                <div>
                  <span>แสดงรายงานการบริการ :</span>
                  <select
                    className="uk-select"
                    onChange={(e) => {
                      this.setState({ personSelect: e.target.value }, () => {
                        this.listSummaryRender();
                      });
                    }}
                  >
                    {this.state.consultList.map((option) => {
                      return (
                        <option value={option.value}>{option.label}</option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div className="uk-width-auto@m uk-width-1-1 uk-text-right">
              <button
                className="uk-button uk-button-primary"
                onClick={this.listGet}
              >
                ค้นหา
              </button>
            </div>
          </div>
          {this.staticTableRender()}
          {
            this.paginateRender()
          }
          <div className="uk-overflow-auto uk-margin-top">
            <table className="uk-table uk-table-divider uk-table-striped d-report-table">
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>วันที่และกระบวนการ</th>
                  <th>บริการ</th>
                  <th>แพทย์เจ้าของคนไข้ (ถ้ามี)</th>
                  <th>ชื่อสกุลผู้ป่วย</th>
                  <th>HN</th>
                  <th>ช่องทางจัดส่งยา</th>
                  <th>ส่งเปิด Visit</th>
                  <th>Dx</th>
                  <th>สถานะการโอนเงิน</th>
                  <th>ยอดทั้งหมด</th>
                  <th>ยอดแจ้งโอน</th>
                  <th>แจ้งโอนแล้ว</th>
                  <th>ส่งเบิกยา</th>
                  <th>ส่งออกใบเสร็จ</th>
                  <th>ใบนัด</th>
                  <th>หมายเหตุ</th>
                </tr>
              </thead>
              <tbody>{this.rowRender()}</tbody>
            </table>
          </div>
        </div>
        {this.state.imagePreview && (
          <div className="d-popup-background">
            <div className="d-popup-body uk-width-large">
              <img src={this.state.imagePreview} uk-img="" />
              <div className="uk-margin-top uk-text-right">
                <button
                  className="uk-button uk-button-secondary"
                  onClick={() => this.setState({ imagePreview: null })}
                >
                  ปิดภาพ
                </button>
              </div>
            </div>
          </div>
        )}
        {
          this.state.getItemExpensePopupShow && <div className="d-popup-background">
          <div className="d-popup-body uk-width-large">
            <div className="uk-padding">
              {
                this.state.loadingNotiApi === false ? 
                <div>
                  {this.getItemExpenseData.result === true ?
                  <div>
                    <div><b>ชื่อ : </b> {this.getItemExpenseData.patient_name || '-'}</div>
                    {
                      Array.isArray(this.getItemExpenseData.medical) && this.getItemExpenseData.medical.length ?
                      <div>
                      <hr className="uk-margin-top" />
                      <h4 className="uk-margin-remove">รายการยา</h4>
                        <table className="uk-table uk-table-small uk-table-divider">
                          <thead>
                            <tr className="uk-text-bold"> 
                              <th className="uk-width-1-2">ชื่อยา</th>
                              <th className="uk-width-1-2">วิธีใช้</th>
                            </tr>
                          </thead>
                          <tbody>
                            
                          {
                              this.getItemExpenseData.medical.map((item, index)=>{
                                return <tr key={`item${index}`}>
                                  <td>{item.name}</td>
                                  <td>{item.usage}</td>
                                </tr>
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                      :
                      <></>
                    }
                    
                    {
                      Array.isArray(this.getItemExpenseData.item) ?
                      <div>
                        <hr className="uk-margin-top" />
                        <h4 className="uk-margin-remove">รายการค่าใช้จ่าย</h4>
                        <table className="uk-table uk-table-small uk-table-divider">
                          <thead>
                            <tr className="uk-text-bold"> 
                              <th className="uk-width-1-2">รายการ</th>
                              <th className="uk-width-1-2">จำนวนเงิน</th>
                            </tr>
                          </thead>
                          <tbody>
                            
                            {
                              this.getItemExpenseData.item.map((item, index)=>{
                                return <tr key={`item${index}`}>
                                  <td>{item.name}</td>
                                  <td className="uk-text-right">{item.total_amount}</td>
                                </tr>
                              })
                            }
                          </tbody>
                        </table>
                        <table className="uk-table uk-table-small uk-table-divider">
                          <tbody>
                            <tr>
                              <td>ยอดรวม</td>
                              <td className="uk-text-right">{numeral(this.getItemExpenseData.item_amount_sum).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>เบิกได้</td>
                              <td className="uk-text-right">{numeral(this.getItemExpenseData.item_amount_in_right).format('0,0.00')}</td>
                            </tr>
                            <tr>
                              <td>ยอดชำระ</td>
                              <td className="uk-text-right">{numeral(this.getItemExpenseData.item_amount_over_right).format('0,0.00')}</td>
                            </tr>
                          </tbody>
                          </table>
                      </div>
                      :
                      <div className="uk-padding uk-text-center">ไม่พบรายการค่าใช้จ่าย</div>
                    }
                  </div>
                  :
                  <div className="uk-padding uk-text-center">{this.getItemExpenseData.responseText || 'ไม่พบข้อมูล'}</div> 
                  }
                </div>
                :
                <div className="uk-padding uk-text-center"><span uk-spinner=""></span></div>
              }
            </div>
            <div className="uk-margin-top uk-text-right">
              <button
                className="uk-button uk-button-default"
                onClick={() => this.setState({ getItemExpensePopupShow: false })}
              >
                ปิด
              </button>
              <button
                className="uk-button uk-button-primary uk-margin-left"
                onClick={() => {
                  this.paymentNotiUpdate(this.getItemExpenseData.id, this.getItemExpenseData.campaignid)
                }}
              >
                ยืนยันการส่งแจ้งเตือนรายการชำระเงิน
              </button>
            </div>
          </div>
        </div>
        }
        
      </div>
    );
  }
}
