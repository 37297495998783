import React, { Component } from "react";
import { KeyPage, KeyVideo, NotiData } from "./KnowledgeKey";
import KnowledgeLogAdd from "../Components/KnowledgeLogAdd";

export default class Knowledge extends Component {
  state = {
    loading: false,
    page_1: false,
    page_2: false,
    page_3: false,
    page_4: false,
    page_5: false,
    page_6: false,
    page_7: false,
    page_8: false,
    page_9: false,
    page_10: false,
    page_11: false,
    page_12: false,
    page_13: false,
    page_14: false,
    page_15: false,
    page_16: false,
    page_17: false,
    page_18: false,
    page_19: false,
    page_20: false,
    page_21: false,
    page_22: false,
    page_23: false,
    page_24: false,
    page_25: false,
    page_26: false,
    page_27: false,
    page_28: false,
    page_29: false,
    page_30: false,
    page_31: false,
    page_32: false,
    page_ontime: false,
    local_open_page_1: false,
    local_open_page_2: false,
    local_open_page_3: false,
    local_open_page_4: false,
    local_open_page_5: false,
    local_open_page_6: false,
    local_open_page_7: false,
    local_open_page_8: false,
    local_open_page_9: false,
    local_open_page_10: false,
    local_open_page_11: false,
    local_open_page_12: false,
    local_open_page_13: false,
    local_open_page_14: false,
    local_open_page_15: false,
    local_open_page_16: false,
    local_open_page_17: false,
    local_open_page_18: false,
    local_open_page_19: false,
    local_open_page_20: false,
    local_open_page_21: false,
    local_open_page_22: false,
    local_open_page_23: false,
    local_open_page_24: false,
    local_open_page_25: false,
    local_open_page_26: false,
    local_open_page_27: false,
    local_open_page_28: false,
    local_open_page_29: false,
    local_open_page_30: false,
    local_open_page_31: false,
    local_open_page_32: false,
    local_open_page_ontime: false
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.paramsGet();
  }

  knowledgeVideo = {}

  listRender = () => {
    var _row_top = [];
    var _row = [];
    var _row_video = [];

    for (let k in KeyPage) {
      let _dom = <div uk-lightbox="">
        <a href={KeyPage[k].url} data-caption={KeyPage[k].title} onClick={() => {
          KnowledgeLogAdd(k, this.props.campaignId, this.props.channel);
        }}>
          <img src={KeyPage[k].url} />
          <div className="d-knowledge-media-title">โปรดคลิกที่สื่อเพื่อดูความรู้แนะนำ</div>
        </a>
      </div>

      if (this.state[`page_${k}`] === true) {
        _row_top.push(<div className="d-knowledge-list">
          <div><span uk-icon="chevron-down" className="uk-margin-small-right"></span> {KeyPage[k].title}</div>
          <div className="d-knowledge-media-container">
            {_dom}
          </div>
        </div>
        );
      }

      _row.push(<div className="d-knowledge-list" onClick={() => {
        this.setState({ [`local_open_page_${k}`]: !this.state[`local_open_page_${k}`] });
      }}>
        <div><span uk-icon={this.state[`local_open_page_${k}`] === true ? 'chevron-down' : 'chevron-right'} className="uk-margin-small-right"></span> {KeyPage[k].title}</div>
        {
          this.state[`local_open_page_${k}`] === true && <div className="d-knowledge-media-container" onClick={(e) => {
            e.stopPropagation();
          }}>{_dom}
          </div>
        }
      </div>)
    }

    for (let k in KeyVideo) {
      _row_video.push(<div className="d-knowledge-list" onClick={() => {
        this.setState({ [`local_open_page_${k}`]: !this.state[`local_open_page_${k}`] });
      }}>
        <div><span uk-icon={this.state[`local_open_page_${k}`] === true ? 'chevron-down' : 'chevron-right'} className="uk-margin-small-right"></span> {KeyVideo[k].title}</div>
        {
          this.state[`local_open_page_${k}`] === true && <div className="d-knowledge-media-container" uk-lightbox="">
            <a href={KeyVideo[k].url} data-caption={KeyVideo[k].title} onClick={(e) => {
              e.stopPropagation();
              KnowledgeLogAdd(k, this.props.campaignId);
            }}>
              <div className="d-knowledge-media-video-container" style={{
                backgroundImage: `url(${KeyVideo[k].cover_url})`
              }}>
                
              </div>
            </a>
          </div>
        }
      </div>)
    }



    return [
      _row_top.length > 0 && <div className="uk-margin-top">
        <div id="d-knowledge-title" className="uk-padding-small uk-text-bold">ชุดความรู้แนะนำ (กดที่หัวข้อเพื่อดูรายละเอียด)</div>
      </div>,
      _row_top,
      <div className="uk-margin-top">
        <div id="d-knowledge-title" className="uk-padding-small uk-text-bold">ความรู้แนะนำทั้งหมด (กดที่หัวข้อเพื่อดูรายละเอียด)</div>
      </div>,
      _row,
      <div className="uk-margin-top">
        <div id="d-knowledge-title" className="uk-padding-small uk-text-bold">วีดีโอความรู้แนะนำ (กดที่หัวข้อเพื่อดูรายละเอียด)</div>
      </div>,
      _row_video
    ]
  }

  paramsGet = () => {
    var _params = Object.fromEntries(new URLSearchParams(this.props.location?.search || this.props.tag));

    if (_params.tag != null) {
      var _tag = _params.tag.split(',');

      var _d = NotiData(_tag);
      this.setState(_d);

    } else if (_params.id != null) {
      var _ids = _params.id.split(',');
      var _d = {};
      for (var id of _ids) {
        _d[`page_${id}`] = true;
      }
      this.setState(_d);
    }
  }

  render() {
    return (
      <div id="d-knowledge-container">
        {
          this.listRender()
        }
      </div>
    );
  }
}
