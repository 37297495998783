import React, { Component } from 'react';
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import $ from 'jquery';
import __ from './Language';

export default class LoginForm extends Component {

  state = {
    username: '',
    password: '',
    accept: false
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = () => {

  }

  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  login = (e) => {
    e.preventDefault();

    try {

      if(!this.state.username || !this.state.password) {
        throw new Error('โปรดใส่ชื่อผู้ใช้และรหัสผ่าน');
      } else if(this.state.accept !== true) {
        throw new Error('โปรดยอมรับเงื่อนไขและนโยบาย');
      } else {
        this.props.onSubmit(this.state);
      }
  
      
    } catch(e) {
      UIkit.notification(`<span uk-icon='icon: close'></span> ${__(e.message)}`);
    }

  }

  render() {
    return <div>
      {
        this.props.loading ?
          <div className="uk-text-center uk-padding-small">
            <span uk-spinner=""></span>
          </div>
          :
          <form onSubmit={this.login} className="uk-margin-top uk-text-left" >
            <div className="uk-inline uk-width-1-1">
              <div>{__('ชื่อผู้ใช้')}<span className="uk-text-danger">*</span></div>
              <input className="uk-input" type="text"  name="username" onChange={this.updateValue} />
            </div>
            <div className="uk-inline uk-width-1-1 uk-margin-small-top">
              <div>{__('รหัสผ่าน')}<span className="uk-text-danger">*</span></div>
              <input className="uk-input" type="password"  name="password" onChange={this.updateValue} />
            </div>
            <div className="uk-margin-top">
              <label uk-grid="" className="uk-grid-small">
                <div className="uk-width-auto">
                  <input type="checkbox" className="uk-checkbox" checked={this.state.accept} onChange={(e)=>{ this.setState({accept:$(e.target).prop('checked')}) }} />
                </div>
                <div className="uk-width-expand">
                  {__('คุณได้อ่านและยอมรับ')} <a href="https://dietz.asia/terms.html" target="_blank" rel="noopener" className="uk-link-reset"><u>{__("ประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)")}</u></a>
                </div>
              </label>
            </div>
            <button className="uk-button uk-button-primary uk-width-1-1 uk-margin-top" type="submit">{__('เข้าสู่ระบบ')}</button>
            {this.props.type !=='consult' && <div>
              <div className="uk-margin-top uk-text-center">
                {__('ถ้ายังไม่ได้ลงทะเบียน')} <Link to={'/register'} className="uk-link-reset"><u>{__('ลงทะเบียนที่นี่')}</u></Link>
              </div>
              <div className="uk-margin-small-top uk-text-center"><Link to={'/password-recover'} className="uk-link-reset"><u>{__('ลืมชื่อผู้ใช้หรือรหัสผ่าน กดที่นี่')}</u></Link></div>
            </div>
            }
          </form>
      }

    </div>
  }
};
