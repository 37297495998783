import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import Hiv2Data from '../../Components/Hiv2Data';
import {ScoreLabel, FormByKey} from '../../Components/HivSelfStigmaData';
import $ from 'jquery';
import {KeyPage, KeyVideo} from '../KnowledgeKey';

UIkit.use(Icons);

var MONTH = ['01','02','03','04','05','06','07','08','09','10','11','12'];

var KEY_WITHOUT_AMOUNT = [];

var KEY_STAT = Hiv2Data;

export default class Hiv2Table extends Component {

  state = {
    loadingData: false,
    campaignHiv: true,
    showBody: false
  }


  hivData = {};

  tagData = {};

  sefStigmaData = {};

  knowledgeData = {};

  HivDataGet = async () => {
    this.setState({loadingData: true});

    var _response = await Api[this.props.apiType || 'consult']("Hiv2DataTableGet", {
      year: this.props.year,
      campaignId: this.props.campaignId || 'all'
    });
 
 
    if(!Array.isArray(_response.data)) _response.data = [];
    if(!Array.isArray(_response.healthnoti)) _response.healthnoti = [];
    if(!Array.isArray(_response.selfstigmaData)) _response.selfstigmaData = [];

    this.hivData = {};
    this.tagData = {};
    this.sefStigmaData = {};
    this.knowledgeData = {};
 
    _response.data.forEach((data) => {

      var _key = `${data.keyname}${(data.value === 'on' ? '' : `_${data.value}`)}`;

      var _value = data.amount;
      
      
      if(KEY_WITHOUT_AMOUNT.includes(data.keyname) === true) {
        _key = data.keyname;
        _value = data.amount;
      }

      if(this.hivData[_key] == null) {
        this.hivData[_key] = {
          sum: 0,
          month: {}
        };
      }

      var _month = moment(data.dateadd, 'YYYY-MM-DD').format('MM');

      this.hivData[_key].sum += parseInt(_value);
      this.hivData[_key].month[_month] = parseInt(_value);
    })
    
    for(var data of _response.healthnoti) { 

      if(this.tagData[data.notikey] == null) {
        this.tagData[data.notikey] = {
          sum: 0,
          month: {}
        };
      }

      var _month = moment(data.datelog, 'YYYY-MM-DD').format('MM');
  

      this.tagData[data.notikey].month[_month] = parseInt(data.amount);
      this.tagData[data.notikey].sum += parseInt(data.amount);
    } 
    

    var _selfstigma = {};
    var _point = [1,2,3,4];

    for(var k in FormByKey) {
      _selfstigma[k] = {
        title: FormByKey[k],
        data: {}
      }



      _point.forEach(function(v) {
        _selfstigma[k].data[v] = {
          sum: 0,
          month: {},
          title: ScoreLabel[v] + ` (${v})`
        } 

      })
    }


    for(data of _response.selfstigmaData) { 
      var _month = moment(data.dateadd, 'YYYY-MM-DD').format('MM');


      if(_selfstigma[data.keyname] != null) {
        if(_selfstigma[data.keyname].data[data.value] != null) {
          if(_selfstigma[data.keyname].data[data.value].month[_month] == null) {
            _selfstigma[data.keyname].data[data.value].month[_month] = 0;
          }

          _selfstigma[data.keyname].data[data.value].month[_month] += parseInt(data.amount);
          _selfstigma[data.keyname].data[data.value].sum += parseInt(data.amount);
        }
      }

    }
 
 
    this.sefStigmaData = _selfstigma;

    this.setState({loadingData: false, campaignHiv: _response.campaignHiv});

  }

  knowledgeDataStatKey = [
    'member-amount',
    'consult-send',
    'auto-noti',
    'self'
  ]

  knowledgeDataStatKeyLabel = {
    'member-amount' : 'จำนวนคนเข้าดูชุดความรู้',
    'consult-send' : 'จำนวนครั้งเข้าดูจากแพทย์กดส่งให้',
    'auto-noti' : 'จำนวนครั้งเข้าดูจากการแจ้งเตือน Alert',
    'self' : 'จำนวนครั้งเข้าดูจากผู้รับบริการกดเอง'
  }

  knowledgeDataGet = async () => {

    this.setState({loadingData: true});

    var _response = await Api[this.props.apiType || 'consult']("KnowledgeDataTableGet", {
      year: this.props.year,
      campaignId: this.props.campaignId || 'all'
    });

    if(!Array.isArray(_response.knowledgelog)) _response.knowledgelog = [];

    for(var data of _response.knowledgelog) { 

      if(this.knowledgeData[data.knowledgekey] == null) {
        var _title = data.knowledgekey;

        if(KeyPage[data.knowledgekey] != null) {
          _title = KeyPage[data.knowledgekey].title;
        }
        else if(KeyVideo[data.knowledgekey] != null) {
          
          _title = KeyVideo[data.knowledgekey].title + ' (วีดีโอ)';
        }

        this.knowledgeData[data.knowledgekey] = {
          data: {},
          title: _title
        };

        
        this.knowledgeDataStatKey.forEach((channel)=>{
          this.knowledgeData[data.knowledgekey].data[channel] = {
            sum: 0,
            month: {},
            title: this.knowledgeDataStatKeyLabel[channel]
          }
        });

      } 

      var _month = moment(data.datelog, 'YYYY-MM-DD').format('MM');

      if(this.knowledgeData[data.knowledgekey].data[data.channel] != null) {
        if(this.knowledgeData[data.knowledgekey].data[data.channel].month[_month] == null) {
          this.knowledgeData[data.knowledgekey].data[data.channel].month[_month] = {
            amount: 0,
            member: []
          }
        }

        this.knowledgeData[data.knowledgekey].data[data.channel].month[_month].amount += parseInt(data.amount);
        this.knowledgeData[data.knowledgekey].data[data.channel].sum += parseInt(data.amount);

        if(this.knowledgeData[data.knowledgekey].data[data.channel].month[_month].member.includes(data.memberid) === false) {
          this.knowledgeData[data.knowledgekey].data[data.channel].month[_month].member.push(data.memberid);
        }

      }

    }

    for(var key in this.knowledgeData) {
      var _member_amount_sum = 0;
      var _member_amount_by_month = {};
      for(var channel in this.knowledgeData[key].data) {
        if(this.knowledgeData[key].data[channel] !== 'member-amount') {
          for(var month in this.knowledgeData[key].data[channel].month) {
            if(_member_amount_by_month[month] ==null) {
              _member_amount_by_month[month] = 0;
            }

            _member_amount_by_month[month] += this.knowledgeData[key].data[channel].month[month].member.length;
            _member_amount_sum += this.knowledgeData[key].data[channel].month[month].member.length;
          }
        }
      }

      this.knowledgeData[key].data['member-amount'].sum = _member_amount_sum;
      this.knowledgeData[key].data['member-amount'].month = _member_amount_by_month;
    }

    this.setState({loadingData: false});
  }

  rowRender = (d, depth) => {
    var _row = [];
    d.forEach((v)=>{
      var _prefix = '';


      for(var i=0; i<depth; i++) {
        _prefix += '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
      }

      if(depth !== 0) {
        _prefix = _prefix + '-';
      }

      
      _row.push(<tr data-rowspan="false">
        <td colSpan={2}><span dangerouslySetInnerHTML={{__html: _prefix}}></span> {v.title}</td>
        <td className='uk-text-center'>{this.hivData[v.key] ? this.hivData[v.key].sum : ''}</td>
        {
          MONTH.map((m)=>{
            return <td className='uk-text-center' key={v.title + '-' + m}>{this.hivData[v.key] ? (this.hivData[v.key].month && this.hivData[v.key].month[m] ? this.hivData[v.key].month[m] : '') :  ''}</td>
          })
        }
      </tr>);

      if(v.child !=null) {
        _row.push(this.rowRender(v.child, depth + 1))
      }
    });

    return _row;
  }

  
  tagRowRender = () => {
    var _row = [];

    for(var tag in this.tagData) {
      _row.push(
        <tr key={tag} data-rowspan="false"> 
          <td colSpan={2} className='uk-text-left'><span className={"uk-label uk-text-small d-background-red"}>{tag}</span></td>
          <td className='uk-text-center'>{this.tagData[tag].sum || ''}</td>
          {MONTH.map((month)=>{
            return <td key={tag+month} className='uk-text-center'>{this.tagData[tag].month[month] || ''}</td>
          })}
        </tr>
      )
    }

    return _row;
  }

  selfStigmaRowRender = () => {
    var _row = [];
    for(var k in this.sefStigmaData) {
      var _d = this.sefStigmaData[k];

      var _index = 0;
      for(var point in _d.data) {
        _row.push(
          <tr key={`self-stigma-row-${k}`} data-rowspan={_index === 0 ? 'true' : 'false'}>
            {point == 1 && <td className='uk-text-left uk-width-medium' rowSpan={4}>{_d.title}</td>}
            <td className='uk-width-medium'>{_d.data[point].title}</td>
            <td className='uk-text-center'>{_d.data[point].sum || ''}</td>
            {MONTH.map((month)=>{
              return <td key={k+month} className='uk-text-center'>{_d.data[point].month[month] || ''}</td>
            })}
          </tr>
        );

        _index++;
      }

    }

    return _row;
  } 

  TableRender = () => {
    return [
      <tr>
        <td colSpan={15}><b><u>NOTI</u></b></td>
      </tr>,
      this.tagRowRender(),
      <tr>
        <td colSpan={15}><b><u>แบบประเมินตนเองก่อนพบแพทย์</u></b></td>
      </tr>,
      this.rowRender(KEY_STAT, 0),
      <tr>
        <td colSpan={15}><b><u>Self Stigma</u></b></td>
      </tr>,
      this.selfStigmaRowRender(),
    ]
  }

  TableKnowledgeRender = () => {
    var _row = [];

    
    for(var k in this.knowledgeData) {
      var _d = this.knowledgeData[k];

      var _index = 0;
      for(var channel in _d.data) {

        _row.push(
          <tr key={`self-stigma-row-${k}`} data-rowspan={_index === 0 ? 'true' : 'false'} >
            {_index === 0 && <td className='uk-text-left uk-width-medium' rowSpan={4}>{_d.title}</td>}
            <td className='uk-width-medium'>{_d.data[channel].title}</td>
            <td className='uk-text-center'>{_d.data[channel].sum || ''}</td>
            {MONTH.map((month)=>{
              return <td key={k+month} className='uk-text-center'>{_d.data[channel].month[month] ? _d.data[channel].month[month].amount : ''}</td>
            })}
          </tr>
        );

        _index++;
      }

    } 

    return _row;
  }
 
  CsvExport = () => {
    var _header = [];
    $('#d-table-data > thead > tr:eq(0) > th').each(function() {
      _header.push(`"${$(this).text()}"`);

      if($(this).attr('colspan')) {
        _header.push(`""`);
      }
    })

    var _csv = [`${_header.join(",")}`];



    $('#d-table-data > tbody > tr').each(function() {
      var _row = [];

      if($(this).attr('data-rowspan') !== 'true') {
        _row.push(`""`);
      }
      
      $(this).find('td').each(function() {
        _row.push(`"${$(this).text()}"`);
      })

      _csv.push(`${_row.join(",")}`);
    })


    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent("\uFEFF"+_csv.join('\r\n')));
    a.setAttribute('download', `แบบประเมินตนเองก่อนเข้าพบแพทย์สำหรับข้อมูลสร้างเสริมสุขภาพ-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`);
    window.document.body.appendChild(a);
    a.click();
  }

  CsvKnowledgeExport = () => {
    
    var _header = [];
    $('#d-table-knowledge-data > thead > tr:eq(0) > th').each(function() {

      _header.push(`"${$(this).text()}"`);

      if($(this).attr('colspan')) {
        _header.push(`""`);
      }
    })

    var _csv = [`${_header.join(",")}`];



    $('#d-table-knowledge-data > tbody > tr').each(function() {
      var _row = [];

      if($(this).attr('data-rowspan') !== 'true') {
        _row.push(`""`);
      }

      $(this).find('td').each(function() {
        _row.push(`"${$(this).text()}"`); 
      })

      _csv.push(`${_row.join(",")}`);
    })
 
    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent("\uFEFF"+_csv.join('\r\n')));
    a.setAttribute('download', `ชุดความรู้-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`);
    window.document.body.appendChild(a);
    a.click();

  }
 
  render() {
    return this.props.hideIfNoHiv !== true || (this.props.hideIfNoHiv && this.state.campaignHiv === true) ? [
      <div className="uk-card uk-card-default uk-padding-small uk-margin-top ">
        <div className="d-card-header pointer">
          <div class="d-card-header">
            <div class="d-card-header-left">แบบประเมินตนเองก่อนเข้าพบแพทย์สำหรับข้อมูลสร้างเสริมสุขภาพ (v2)</div>
            <div class="d-card-header-right">
              {
                this.state.showBody === true && <button className='uk-button d-text-white uk-button-primary' onClick={this.CsvExport}><span uk-icon="download"></span> Export CSV</button>
              }
              
            </div>
          </div>
        </div>
        {
          this.state.showBody === true ?
          <div className="uk-overflow-auto">
            <table className='uk-table uk-table-divider uk-table-small d-table-border-vertical uk-text-left uk-table-striped uk-table-hover' id="d-table-data">
              <thead>
                <tr> 
                  <th colSpan={2}></th> 
                  <th className='uk-text-center'>รวม</th>
                  {MONTH.map((data)=>{
                    return <th className='uk-text-center'>{data}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {
                  this.state.loadingData === true ?
                  <div><span uk-spinner=""></span></div>
                  :
                  this.TableRender()
                }
              </tbody>
            </table>
          </div>
          :
          <div onClick={()=>{
            this.setState({
              showBody: true
            }, ()=>{
              this.HivDataGet();
            })
          }}>
            <button className='uk-width-1-1 uk-button uk-button-default'>รายละเอียดถูกซ่อนอยู่ <u>คลิกที่นี่</u>เพื่อแสดงรายละเอียด</button>
          </div>
        }
      </div>,

      <div className="uk-card uk-card-default uk-padding-small uk-margin-top ">
      <div className="d-card-header pointer">
        <div class="d-card-header">
          <div class="d-card-header-left">ชุดความรู้</div>
          <div class="d-card-header-right">
            {
              this.state.showBodyKnowledge === true && <button className='uk-button d-text-white uk-button-primary' onClick={this.CsvKnowledgeExport}><span uk-icon="download"></span> Export CSV</button>
            }
            
          </div>
        </div>
      </div>
      {
        this.state.showBodyKnowledge === true ?
        <div className="uk-overflow-auto">
          <table className='uk-table uk-table-divider uk-table-small d-table-border-vertical uk-text-left uk-table-striped uk-table-hover' id="d-table-knowledge-data">
            <thead>
              <tr> 
                <th colSpan={2}></th> 
                <th className='uk-text-center'>รวม</th>
                {MONTH.map((data)=>{
                  return <th className='uk-text-center'>{data}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {
                this.state.loadingData === true ?
                <div><span uk-spinner=""></span></div>
                :
                this.TableKnowledgeRender()
              }
            </tbody>
          </table>
        </div>
        :
        <div onClick={()=>{
          this.setState({
            showBodyKnowledge: true
          }, ()=>{
            this.knowledgeDataGet();
          })
        }}>
          <button className='uk-width-1-1 uk-button uk-button-default'>รายละเอียดถูกซ่อนอยู่ <u>คลิกที่นี่</u>เพื่อแสดงรายละเอียด</button>
        </div>
      }
      </div>
    ]
    :
    null
  }
};
